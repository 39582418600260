@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';

.form-accordion {
  &.shared-accordion {
    @include box-shadow-bottom;

    .collapse {
      background-color: $bg-3a;
      padding-bottom: 8px !important;
      padding-top: 8px !important;
    }
  }
}

.message-template-accordion {
  .shared-accordion {
    @include box-shadow-bottom;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .title {
      border-bottom: 1px solid $bg-1;
      padding: 0px;
      padding-left: 8px;
      //margin-top: 8px;
      color: $primary-complement;
    }

    .collapse {
      background-color: $bg-2;
      padding-bottom: 8px !important;
      padding-top: 8px !important;
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

.game-settings {
  .nested-accordion {
    margin-bottom: 8px;

    .shared-boxContent {
      margin: 8px 15px;
    }
  }

  .no-nested-accordion {
    margin-bottom: 8px;

    .shared-boxContent {
      margin: 0px;
    }
  }

  .shared-boxContent {
    display: block;
    margin-bottom: 12px;
    border-radius: $border-radius;
  }
}
