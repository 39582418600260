@import 'variables';
@import '../../mixin';

.card-shadow {
  display: flex;

  @include box-shadow-center;
  border-radius: $border-radius;
}

.card-shadow-gamereport {
  @include box-shadow-center;
  //border-radius: $border-radius;
}
