@import 'font';
@import './icon/style';
@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import './core';
@import './features';
@import './../components/shared/shared';
@import './fontAwesome';
@import './reactCheckboxTree';

.pointer {
    cursor: pointer;
}
.immagine {
    background-color: rgba(0, 255, 0, 0.2);
    // width: 250px;
    // height: 100px;
    font: 48px Arial;
    font-weight: bold;
}

.d-flex-col-beetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
hr {
    border-top: 1px solid $primary;
    opacity: 0.2;
}

.fullscreen-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; /* Assicura che l'immagine copra l'intero schermo */
    background-position: center;
    z-index: 1000; /* Assicurati che sia sopra gli altri elementi */
    display: flex;
    justify-content: center;
    align-items: center;
}
