@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';
@import './../../../components/shared/mediaQuery';

.game-answers-container {
  margin-top: 12px;
  padding: 20px;
  min-height: 290px;
  flex-direction: column;
  justify-content: space-between;

  & .answer-item {
    align-items: center;
    padding: 8px;
    margin-bottom: 8px;
    @include box-shadow-center-hover;
    cursor: pointer;

    &.checked {
      background-color: $color-1;
    }

    label {
      margin-bottom: 0px;
      cursor: pointer;
    }
  }

  @media (max-width: $md-device) {
    min-height: auto;

    .answer-item {
      font-size: 14px;

      .shared-input {
        font-size: 16px;
      }
    }
  }
}
