@import 'variables';
@import '../../../../shared/mediaQuery';

.toolbar-btn {
    background-color: $white;
    padding: 8px;
    width: fit-content;
    cursor: pointer;

    &:hover {
        box-shadow: $box-shadow-hover;
    }

    @media (max-width: $md-device) {
        img,
        .fa-custom {
            width: 28px;
            height: 28px;
        }

        .shared-icon {
            padding: 0px;

            .icon {
                font-size: 28px !important;
            }
        }
    }
}
