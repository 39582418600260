$bgTHeader: #f5f6f9 !default;
$colorTHeader: #01a381 !default;

$borderColorTRow: #d0e0ed !default;
$colorTRow: #445167d9 !default;
$colorTRowActive: #e68047 !default;

$radius: 4px;
$bgTBody: #f5f8fb !default;
$fontFamilyTHeader: 'Roboto-bold' !default;
$fontSizeTHeader: 12px !default;
$fontSizeTBody: 16px !default;
