@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';
@import './../../../components/shared/mediaQuery';

.gamereport-container {
  margin: 0px;
  padding: 0px;
  border: 0px;

  .bg-image {
    background-attachment: fixed;
    width: 100%;
    height: calc(100vh - #{$heightHeader} - #{$heightFooter});
    left: 0px;
    background-size: cover;
    background-color: #ffffff;
    position: absolute;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: fixed;
    top: $heightHeader;
  }
  .content-list {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    margin: 1px;
    position: absolute;
    z-index: 1;
    background-color: transparent;
    border: 0;
    z-index: 0;
  }
  .content-form {
    padding-top: 40px;
    margin: 4px;
    place-items: center;
    width: 60px;
    display: flex;
    background-color: transparent;
    position: relative;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    z-index: 2;
    border: 1;
  }
  @media (max-width: $md-device) {
    margin-right: 50px;
  }
}

.row-gamereport-item {
  justify-content: space-between;
  margin: 0px;
  padding: 6px 12px;
  border: 0px;
  border-bottom: solid 1px;
  border-color: $bg-4;
  border-radius: 0px;
  font-size: 14px;
  //@include box-shadow-center-hover;
}
.bgwhite {
  background-color: $bg-1;
}
.bggrey {
  background-color: $bg-2;
}

/*
AlPhaD UI - Ugarit layout

* Version: 1.0.0
* Build Date    : 18 12 2017
* Last Update   : - []
* Author: ALPhA D
* Website: www.alphadsy.com
* contribute on github : https://github.com/alphadsy/alpha-ui
more ui & layout https://github.com/alphadsy
--------------------
*/

/* === page-container ===*/
.left-side,
.right-side {
  margin-top: 10vh;
  width: calc(100% - 12px);
  padding: 6px;
  float: left;
}

/* === left-side ===*/
.left-side {
  margin-top: 10vh;
}
.brand {
  min-height: 20vh;
  margin-top: 0vh;

  color: #fff;
  text-align: left;
}

.gamereport-searchform {
  background-color: $primary;
}

/* === right-side ===*/
.wall {
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  background-color: rgb(255, 255, 255);
  color: #2a2a2a;

  //border-radius: 15px;
}

/* === wall content ===*/
.wall .section {
  padding: 20px;

  text-align: center;
}
/* intro */
.section.intro {
  /*
  any spacial css for this section
  */
}
/* =======================================
2- Small devices (landscape phones, 576px and up)
========================================*/
@media (min-width: 576px) {
}

/* =======================================
3- Medium devices (tablets, 768px and up)
========================================*/
@media (min-width: 768px) {
}

/* =======================================
4- Large devices (desktops, 992px and up)
========================================*/
@media (min-width: 992px) {
  /* === page-container ===*/
  .left-side,
  .right-side {
    width: 50%;

    padding: 7px;
  }

  /* === left-side ===*/
  .left-side {
  }
  .brand {
    min-height: 40vh;
    margin-top: 30vh;
  }

  /* === wall ===*/
  .wall {
    height: calc(85vh);
    overflow-x: auto !important;

    //box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }

  /* === wall content ===*/
  .wall .section {
    min-height: calc(85vh);
  }
  /* intro */
  .section.intro {
    /*
  any spacial css for this section
  */
  }
}

/* =======================================
5- Extra large devices (large desktops, 1200px and up)
========================================*/
@media (min-width: 1200px) {
}
