@import '../../../components/shared/mediaQuery';

.dropdown-user {
  padding: 0px;
  color: #e68047;
  &::after {
    display: none;
  }
  &:hover {
    color: #e68047;
  }
}

.logo-toolbar {
  padding-left: 160px;

  .logo-img {
  }
}

@media (max-width: $md-device) {
  .logo-toolbar {
    padding-left: 100px;
    .logo-img {
      img {
        width: 64px;
        height: auto;
      }
    }
  }
}
