@import 'variables';
.shared-cardWithDropdown {
  background-color: $cardWithDropdownColorDefault;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  min-height: 134px;
  margin-bottom: 24px;
  .left-side {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12px;
    margin: 6px 12px;
    .caption {
      margin-top: 8px;
      min-width: 120px;
      max-width: 120px;
      justify-content: center;
      text-align: center;
      line-height: initial;
    }
  }
  .right-side {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 12px;
    .labelDrop {
      color: $cardWithDropdownlightColor;
    }
    .shared-dropdown {
      &.show.dropleft {
        & .dropdown-toggle {
          .shared-icon {
            .icon {
              &::before {
                color: $cardWithDropdownColorHighlight;
              }
            }
          }
        }
      }
    }
  }
  &.disabled {
    //opacity: 0.8;
    pointer-events: none;
    background-color: #2793f321;
  }
}
