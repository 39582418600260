$widthBoxContent: 100% !default;
$heightBoxContent: 205px !default;
$borderRadiusBoxContent: 4px !default;

$borderWidthSizeActive: 4px;
$borderWidthSize: 2px;

$defaultBgBoxContent: #f5f8fb;

$darkBgBoxContent: #999999;
$lightBgBoxContent: #fff;

$darkTextBoxContent: #fff;
$lightTextBoxContent: #000;
