@import 'variables';
.shared-toast {
  margin-bottom: 4px;
  .toast {
    & .toast-header {
      min-height: 40px;
      font-size: 16px;
      background-color: transparent;
      padding: 12px;
      & .close {
        color: #fff;
        &.ml-2 {
          margin-left: 16px !important;
        }
      }
    }
    & .toast-body {
      padding: 12px 24px;
    }
  }
}
