@import './../../../components/shared/variables';
@import './../../../components/shared/mediaQuery';

.answer-message-container {
  margin-top: 25px;
  padding: 20px;
  min-height: 370px;
  flex-direction: column;
  justify-content: space-between;

  .block-message {
    padding: 48px;
    text-align: center;
  }

  @media (max-width: $md-device) {
    .block-message {
      padding: 24px;
    }

    min-height: auto;
  }
}
