@import 'variables';
@import './../../../../mediaQuery.scss';

.shared-button {
  width: $widthButton;
  height: $heightButton;
  border-radius: $borderRadiusButton;
  font-size: $fontSizeButton;
  font-family: $fontFamilyButton;
  line-height: normal;
  padding: 0px 8px;

  @media (max-width: $md-device) {
    font-size: $fontSizeSmallButton;
  }
}

.md {
  height: $heightMediumButton;
  font-size: $fontSizeMediumButton;
  line-height: normal;
}

.sm {
  height: $heightSmallButton;
  font-size: $fontSizeSmallButton;
  line-height: $lineheightSmallButton;
}
