@import 'variables';

.shared-popup {
  width: $width-popup;
  .popover {
    left: -100px;
    border: none;
    box-shadow: 0 3px 14px #00000044;
    .arrow {
      margin: 0 48%;
      &::before {
        border-top-color: transparent;
      }
    }
  }
}
