@import 'variables';
@import './../../../shared/mediaQuery.scss';

.timer {
  position: relative;
  height: $heightTimer;
  width: $widthTimer;
}

.timer-svg {
  transform: scaleX(-1);
}

.timer-circle {
  fill: none;
  stroke: none;
}

.timer-path-elapsed {
  stroke-width: $strokeWidthPath;
}

.timer-label {
  position: absolute;
  width: $labelWidth;
  height: $labelHeight;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $labelFontSize;
}

.timer-path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  stroke: currentColor;
}

@media (max-width: $md-device) {
  .timer {
    width: $widthTimer-sm;
    height: $heightTimer-sm;
  }
  .timer-label {
    width: $labelWidth-sm;
    height: $labelHeight-sm;
    font-size: $labelFontSize-sm;
  }
}
