@import 'components/publicLayout';
@import 'components/Header';
@import 'components/Footer';
@import './AuthLayout.scss';
.containerApp {
  margin-top: $heightHeader;
}

.container-app-menu {
  margin-top: 88px;
}
