.shared-button-tab {
  background-color: #f5f6f9;
  padding: 4px 16px;
  font-family: 'Roboto-medium';
  cursor: pointer;
  margin-left: 6px;
  &:disabled {
    background-color: #2288ee;
    color: white;
    opacity: initial;
    cursor: initial;
  }
}
