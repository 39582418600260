$heightInputDate: 32px !default;
$heightMediumInputDate: 28px !default;
$heightSmallInputDate: 24px !default;

$fontFamilyInputDate: 'Roboto-regular' !default;

$fontSizeInputDate: 16px !default;
$fontSizeMediumInputDate: 16px !default;
$fontSizeSmallInputDate: 16px !default;

$lineheightMediumInputDate: 24px !default;
$lineheightSmallInputDate: 19px !default;

$fontFamilyIconInputDate: 'icon-pack' !default;
$iconInputDate: '\e923' !default;
$colorInputDate: #565b6f !default;
