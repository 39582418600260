.shared-boxTools {
  position: fixed;
  bottom: 92px;
  left: 25%;
  width: 50%;
  min-width: 260px;
  height: 60px;
  background-color: #0091f6;
  z-index: 10;
  box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  border-radius: 4px;
  & .caption {
    padding: 0px 24px;
    justify-content: center;
    color: #fff;
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
}
