@import './../../../components/shared/variables';
@import './../../../components/shared/mediaQuery.scss';

.game-time-score {
  //min-height: 370px;
  margin-top: 25px;
  padding: 20px;
  //height: calc(100% - 26px);

  @media (max-width: $md-device) {
    min-height: auto;
    font-size: 14px;
  }
}

.game-header-skills {
  //min-height: 370px;
  margin-top: 25px;
  padding: 20px;
  height: max-content;
  //height: calc(100% - 26px);

  @media (max-width: $md-device) {
    min-height: auto;
    font-size: 14px;
  }
}
