@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';
@import './../../../components/shared/mediaQuery';

.summary-info-container {
  justify-content: space-between;
  align-items: center;
  margin-top: $heightHeader + 20px;
  padding: 10px;
}

.summary-title-container {
  font-size: 24px;
  font-weight: 700;
}

.summary-block-info {
  background-color: $color-1;
  padding: 4px 12px;
  border-radius: $border-radius;
  margin-right: 16px;
  @include box-shadow-center;
}

@media (max-width: $md-device) {
  .summary-info-container {
    flex-direction: column;
  }

  .summary-title-container {
    font-size: 16px;
    font-weight: 700;
  }

  .summary-block-info {
    padding: 4px 4px;
    margin-right: 8px;

    font-size: 12px;

    &:last-child {
      margin-right: 0px;
    }
  }
}
