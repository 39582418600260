@import './../../../../components/shared/variables';
@import './../../../../components/shared/mixin';

.footer {
  background-color: $bgFooter;
  height: $heightFooter;
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100%;
  z-index: 100;
  @include box-shadow-top;

  //background-color: black;
}
