@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';

.content-toolbar {
  padding: $border-radius;
  background-color: $toolbar-search-background;
  width: $toolbar-search-width;
  display: flex;
  justify-content: space-around;
}
.search-toolbar-content {
  padding: 20px;
  background: $toolbar-search-content-background;
  min-width: $toolbar-search-content-width;
  display: flex;
  justify-content: center;
  align-content: center;
  @include box-shadow-bottom;
}
