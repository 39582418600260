@import 'src/components/shared/_mediaQuery.scss';

.emotion-view {
    position: fixed;
    padding: 3px;
    z-index: 111;
    background-color: white;
    opacity: 0.6;
    bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20%;
    width: 88px;
    height: 88px;
    transform: scale(0.5);
    left: -10px;

    @media (min-width: $md-device) {
        transform: scale(0.645);
        border-radius: 15%;
        left: 0;
        bottom: 80px;
    }
}

.hide {
    left: -100%;
}
