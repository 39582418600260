$widthTimer: 200px;
$heightTimer: 200px;
$widthTimer-sm: 80px;
$heightTimer-sm: 80px;
$strokeWidthPath: 7px;
$labelWidth: 200px;
$labelHeight: 200px;
$labelWidth-sm: 80px;
$labelHeight-sm: 80px;
$labelFontSize: 48px;
$labelFontSize-sm: 22px;
$hoursMinutesColor: #f00;
$hoursMinutesFontSize: 24px;
$hoursMinutesFontWeight: 700;
