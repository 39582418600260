@import '~leaflet/dist/leaflet.css';
@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';

.leaflet-container {
  height: calc(100vh - #{$heightHeader} - #{$heightFooter});
  z-index: 50;
  margin-left: -15px;
  margin-right: -15px;
}

.leaflet-touch {
  & .leaflet-bar {
    border: none;

    @include box-shadow-center;

    & > a {
      color: $primary;
    }
  }

  .leaflet-control-attribution {
    font-size: 8px;
  }
}
