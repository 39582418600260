@import 'variables';

.languages-container {
  margin-top: 20px;
  padding-bottom: 40px;

  .shared-boxContent {
    margin-bottom: 20px;
  }

  .content-accordion {
    padding: 20px;
  }
}
