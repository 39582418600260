$icomoon-font-family: 'icon-pack' !default;
$icomoon-font-path: './fonts-icon/' !default;

$icon-check_box: '\e939';
$icon-check_box_blank: '\e93a';
$icon-question_answer: '\e931';
$icon-quiz: '\e932';
$icon-gavel: '\e933';
$icon-leaderboard: '\e934';
$icon-insights: '\e935';
$icon-stars: '\e936';
$icon-video_settings: '\e937';
$icon-play_circle_outline: '\e938';
$icon-add_task: '\e92e';
$icon-published_with_changes: '\e92f';
$icon-save: '\e930';
$icon-exit_to_app: '\e921';
$icon-logout: '\e922';
$icon-arrow_circle_up: '\e923';
$icon-chevron_left: '\e924';
$icon-chevron_right: '\e925';
$icon-expand_more: '\e926';
$icon-expand_less: '\e927';
$icon-save_alt: '\e928';
$icon-arrow_back: '\e929';
$icon-arrow_forward: '\e92a';
$icon-pin_drop: '\e92b';
$icon-arrow_drop_up: '\e92c';
$icon-arrow_drop_down: '\e92d';
$icon-radio_button_checked: '\e91a';
$icon-radio_button_unchecked: '\e91b';
$icon-check_circle_black: '\e91c';
$icon-done_black: '\e91d';
$icon-upload_black: '\e91e';
$icon-upload_file_black: '\e91f';
$icon-download: '\e920';
$icon-category: '\e900';
$icon-add_circle: '\e901';
$icon-add: '\e902';
$icon-remove: '\e903';
$icon-remove_circle: '\e904';
$icon-delete: '\e905';
$icon-redo: '\e906';
$icon-undo: '\e907';
$icon-saved_search: '\e908';
$icon-share_location: '\e909';
$icon-add_location: '\e90a';
$icon-not_listed_location: '\e90b';
$icon-where: '\e90c';
$icon-location_searching: '\e90d';
$icon-sports: '\e90e';
$icon-golf: '\e90f';
$icon-star: '\e910';
$icon-star_black: '\e911';
$icon-history: '\e912';
$icon-travel: '\e913';
$icon-block: '\e914';
$icon-cancel: '\e915';
$icon-close: '\e916';
$icon-app: '\e917';
$icon-menu: '\e918';
$icon-widgets: '\e919';
$icon-clona_config: '\e93b';
