@import 'variables';

.shared-dataInput {
  height: $heightInputDate;
  border: none;
}

input[type='date'] {
  &::-webkit-calendar-picker-indicator {
    color: $colorInputDate;
    //background: none;
    z-index: 1;
  }

  /*   &:before {
    color: transparent;
    background: none;
    display: block;
    font-family: $fontFamilyIconInputDate;
    content: $iconInputDate;
    color: $colorInputDate;
    position: absolute;
    right: 12px;
  } */
}

.md {
  height: $heightMediumInputDate;
  font-size: $fontSizeMediumInputDate;
  line-height: normal;
}

.sm {
  height: $heightSmallInputDate;
  font-size: $fontSizeSmallInputDate;
  line-height: $lineheightSmallInputDate;
}
