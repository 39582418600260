@import 'variables';
@import '../../../shared/mediaQuery';

.heading-3 {
  font-family: $fontFamilyHeading3Bold;
  font-size: $fontSizeHeading3;
  color: #01a381;
  letter-spacing: $letterSpacingHeading3;
  line-height: $lineHeightHeading3;
  @media (max-width: $lg-device) {
    font-size: $fontSizeHeading3Mobile;
  }
}

.heavy {
  font-family: $fontFamilyHeading3Heavy;
}
.medium {
  font-family: $fontFamilyHeading3Medium;
}
