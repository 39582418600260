@import 'variables';

.shared-accordion {
  width: 100%;

  .title {
    width: 100%;
    background: $bgTitleAccordion;
    color: $colorTitleAccordion;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    border-radius: $borderRadiusTitleAccordion;

    & .label-title {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & .label-bold {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-family: $fontFamilyIconDownAccordionBold;
      font-weight: 700;
    }

    & .number {
    }

    &::after {
      font-family: $fontFamilyIconDownAccordion;
      content: $iconDownAccordion;
      font-size: $fontSizeIconDownAccordion;
      margin-left: 16px;
    }

    &.show {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &:not(.not-padding) {
    .collapse {
      padding: 0px 10px;
    }
  }

  .collapse {
    //border: 1px solid #0000001a;
  }
}

.box {
  & .card-body {
    min-height: 0px;
    padding: 0px;
  }
}
