@import 'variables';
#publicLayout {
  display: flex;
  background: $bgGradientPrimary;
  height: 100vh;
  align-items: center;
  justify-content: center;
  > div {
    width: $widthPublicLayout;
    background-image: $bgGradientPublicContainer;
    border: $borderPublicContainer;
    box-shadow: $boxShadowPublicContainer;
    border-radius: $borderRadiusPublicContainer;
    padding: $paddingPublicContainer;
    > div {
      width: 340px;
      margin: 0 auto;
    }
  }
}
