@import './../../../components/shared/variables';
@import './../../../components/shared/mediaQuery';

.resource-container {
    .bg-image {
        background-attachment: fixed;
        width: 100%;
        height: calc(100vh - #{$heightHeader} - #{$heightFooter});
        left: 0px;
        background-size: cover;
        position: absolute;
        z-index: 0;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        position: fixed;
        top: $heightHeader;
    }

    #file-name {
        display: none;
    }
}
