@import 'variables';
@import '../../../../shared/mediaQuery';

.toolbar-top {
  //contenitore d-flex allinearlo al centro e width 0 to sidebar
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  //background-color: yellow;
  position: fixed;
  width: 100%;

  z-index: 100;
  margin: 0px -15px;
  padding: 8px 15px;
  pointer-events: none;
  padding-top: 0px;

  &.open {
    width: calc(100% - #{$sidebar-width-lg});
  }

  & .center-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    pointer-events: auto;
  }

  & .left-content {
    pointer-events: auto;
  }

  & .right-content {
    pointer-events: auto;
  }
}

@media (max-width: $md-device) {
  .toolbar-top {
    margin: 0px -47px;
  }
}
