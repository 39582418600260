@import './../../../components/shared/variables';
@import './../../../components/shared/mediaQuery';

.comment-container {
  margin-right: 75px;
  margin-top: $heightHeader + 20px;

  .bg-image {
    background-attachment: fixed;
    width: 100%;
    height: calc(100vh - #{$heightHeader} - #{$heightFooter});
    left: 0px;
    background-size: cover;
    position: absolute;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: fixed;
    top: $heightHeader;
  }

  .comment-panel {
    position: relative;
    min-width: 280px;
    width: 33%;
    z-index: 1;
    background-color: #ffffffee;
    padding: 20px;

    .item-comment {
      border-bottom: 1px solid #d7d7d7;
      margin-bottom: 12px;
      padding: 12px;

      p {
        margin-bottom: 0px;
      }
    }
  }

  @media (max-width: $md-device) {
    margin-right: 50px;

    .comment-panel {
      width: 50%;
    }
  }
}
