@import './../../../components/shared/variables';
@import 'myProgress';
@import 'gdpr';
@import 'category';
@import 'menuManager';
@import 'languages';
@import 'roleManagement';

.menu-shared {
  #sidebar-menu {
    overflow-y: auto;
    width: 250px;
    position: fixed;
    left: 0;
    height: calc(100vh - #{$heightHeader} - #{$heightFooter});
    z-index: 999;
    background: darken($color: $primary-complement, $amount: 0.5);
    color: #fff;
    transition: all 0.3s;

    &.close {
      margin-left: -250px;
    }

    & .sidebar-header {
      padding: 20px;
      background: $primary-complement-light;
    }

    & ul {
      &.components {
        padding: 20px 0;
      }

      & p {
        color: #fff;
        padding: 10px;
      }

      & li {
        & span {
          padding: 6px 12px;
          font-size: 16px;
          display: block;

          .item {
            &.active {
              text-decoration: underline;
            }
          }

          &:hover {
            color: $primary;
            background: #fff;
            cursor: pointer;
          }
        }
      }
    }

    & .sub-components {
      background: $primary-complement-dark;
      display: none;
      padding: 12px 30px;

      // border-bottom: 1px solid #47748b;
      &.open {
        display: block;
      }

      .sub-item {
        &.active {
          text-decoration: underline;
        }
      }
    }
  }

  #content {
    width: calc(100% - 250px);
    padding: 20px;
    height: calc(100vh - #{$heightHeader} - #{$heightFooter});
    transition: all 0.3s;
    position: absolute;
    right: 0;

    &.close {
      width: 100%;
    }

    & .btn-toogle {
      width: 56px;
      /*  margin-left: -20px;
      margin-top: -20px; */
    }
  }

  @media (max-width: 768px) {
    #sidebar-menu {
      margin-left: -250px;

      &.close {
        margin-left: 0;
      }
    }

    #content {
      width: 100%;

      &.close {
        width: calc(100% - 250px);
      }
    }
  }
}
