@import './../../../components/shared/variables';
@import './../../../components/shared/mediaQuery';

.game-container {
  //margin-right: 75px;

  .bg-image {
    background-attachment: fixed;
    width: 100%;
    height: calc(100vh - #{$heightHeader} - #{$heightFooter});
    left: 0px;
    background-size: cover;
    position: absolute;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: fixed;
    top: $heightHeader;
  }

  .content-game {
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .game-container-center {
    display: flex;
    justify-content: space-between;
  }

  .game-block {
    width: 100%;
    order: 1;
    // margin-right: 25px;
  }

  .game-sidebar {
    order: 2;
    margin-left: 25px;
  }

  @media (max-width: $md-device) {
    margin-right: 50px;

    .game-container-center {
      flex-direction: column;
      display: flex;
      justify-content: space-between;
    }

    .game-sidebar {
      order: 1;
      margin-left: 0px;
    }

    .game-block {
      order: 2;
    }
  }
}
