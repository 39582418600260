$primary: #01a381;
$primary-complement: #f58e53;
$success: #7ed321 !default;
$error: #a10000 !default;
$warning: #ffcd00 !default;
$secondary: #ffffff !default;

$bg-1: #fff !default;
$bg-2: #f5f6f9 !default;
$bg-3: #fafafa !default;
$bg-3a: #dfe6eb66;
$bg-4: #c9cbd9 !default;

$primary-complement-light: #ff9a62;
$primary-complement-dark: #e68047;

$color-1: #c4d6df;
$color-2: $bg-2;

$border-radius: 8px;

/* nav */
$heightHeader: 0px;
$bgHeader: $bg-1;
$navbar-border-bottom: 2px solid $primary;

/* footer */
$heightFooter: 20px;
$bgFooter: $color-1;

/* sidebar */
$sidebar-background: $color-2;
$sidebar-width-lg: 610px;
$sidebar-width-md: 470px;
$sidebar-border-left: 2px solid $primary-complement;

/* center-toolbar */
$toolbar-search-background: $bg-1;
$toolbar-search-width: 200px;

/* center-toolbar-content */
$toolbar-search-content-background: $bg-2;
$toolbar-search-content-width: 300px;

/* btn-toolbar */
$toolbar-btn-width: 56px;
