@import '../../../variables';

$tabsBadgeWidth: 300px;
$tabsBadgeHeight: 60px;
$tabsBadgeFontSize: 1.3em;
$tabsBadgeFontWeight: 700;
$tabsBadgeBoder: $primary 1px solid;
$tabsBadgeColor: #fff;
$singleBadgeWidth: 275px;
$tabsBadgeBgColor: #07afba;
$badgeImgContainerHeight: 180px;
$badgeInfoRowHeight: 25px;
