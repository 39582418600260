@import './../../../../components/shared/variables';
@import './../../../../components/shared/mixin';

.thumb-item {
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  background-color: $bg-1;
  margin: 10px;
  position: relative;
  @include box-shadow-center-hover;

  & .thumb-close {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  & .thumb-img {
    max-width: 80px;
    max-height: 80px;
  }
}
