@import 'variables';
.form-group {
  & .inlineForm {
    display: flex;
    align-items: center;
    & .shared-label {
      margin-bottom: 0;
      margin-right: 8px;
      font-family: $fontFamilyFormGroup;
    }
  }
  & .inlineFormReverse {
    display: flex;
    flex-direction: row-reverse;
    align-items: baseline;
    justify-content: flex-end;
    & .shared-label {
      margin-bottom: 0;
      margin-right: 8px;
      font-family: $fontFamilyFormGroup;
    }
  }
  & .hidden {
    display: none;
  }
}
