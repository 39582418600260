@import './../../../../../components/shared/variables';
@import 'variables';
@import '../../../../shared/mediaQuery';

.toolbar-right {
  //contenitore d-flex allinearlo al centro e width 0 to sidebar
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: fixed;
  height: calc(100vh - #{$heightHeader} - #{$heightFooter} - 30px);
  //width: 80px;
  top: $heightHeader + 20px;
  right: 15px;
  z-index: 100;
  margin: 0px -15px;
  padding: 0px 15px;
  pointer-events: none;

  &.open {
    right: $sidebar-width-lg + 15px;
  }

  & .top-content {
    pointer-events: auto;
  }

  & .center-content {
    pointer-events: auto;
  }

  & .bottom-content {
    pointer-events: auto;
  }
  @media (max-width: $md-device) {
    top: $heightHeader + 10px;
    padding: 0px 10px;
  }
}
