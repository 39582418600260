@import 'variables';

.category-container {
  margin-top: 20px;
  padding-bottom: 40px;

  .category-table {
    tbody {
      tr {
        td {
          padding: 6px;
        }
      }
    }
  }
}
