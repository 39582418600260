@import 'variables';
.content {
  z-index: 100;
  background: #fff;
  width: 50vw;
  height: 50vh;
  position: fixed;
  top: 25%;
  left: 25%;
  &.bordered {
    border: 1px dashed #979797;
  }
  > .dropZone {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
