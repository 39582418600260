@import './../../../shared/mediaQuery';

.lives-container {
  display: flex;
  flex-wrap: wrap;

  & span {
    margin-right: 4px;
  }

  @media (max-width: $md-device) {
    img {
      width: 16px;
      height: 16px;
    }
  }
}
