@import 'variables';

.shared-progress {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .progress {
    height: $heightProgress;
    width: 100%;
  }
  .progress-bar {
    background-color: $colorProgress;
    height: $heightProgress;
  }
  .input-group {
    width: 100px;
    margin-left: 8px;
  }
}
