@import 'variables';

.accordion-badges-container {
  margin: 10px;
}

.badges-list-container {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  margin-top: 10px;
}

.row-info-title {
  background-color: $primary;
  color: $tabsBadgeColor;
  font-size: $tabsBadgeFontSize;
  font-weight: $tabsBadgeFontWeight;
  width: $tabsBadgeWidth;
  border-radius: 8px;
  margin: 10px;
  display: flex;
  justify-content: center;
  height: 55px;
  align-items: center;
  cursor: default;
}

.badges-accordion {
  & .btn.title {
    & span {
      font-size: 1.8em;
      font-weight: 300;
    }
  }
}
