@import 'variables';
.dropzone-box {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  width: 100%;
}
