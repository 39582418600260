@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';

.game-info-container {
  & .info-drop {
    flex-direction: column;
    background-color: $bg-1;
    padding: 16px;
    position: absolute;
    right: $toolbar-btn-width + 34px;
    top: 0px;
  }
}
