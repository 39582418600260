@import 'variables';
@import './../../../shared/mediaQuery';

.countdown-hours-minutes {
  display: flex;
  justify-content: center;
  font-size: $hoursMinutesFontSize;
  font-weight: $hoursMinutesFontWeight;
  color: $hoursMinutesColor;

  @media (max-width: $md-device) {
    font-size: 14px;
  }
}
