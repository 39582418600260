@import 'variables';

.gdpr-container {
  margin-top: 20px;
  padding-bottom: 40px;

  .gdpr-table {
    tbody {
      tr {
        td {
          padding: 6px;

          & .label {
            display: inline;
            color: #fff;
            background-color: $primary;
            font-size: 14px;
            padding: 0px 4px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
