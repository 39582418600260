@import '../../components/shared/variables';
@import '../../components/shared/mediaQuery';

.content-page {
  height: calc(100vh - #{$heightHeader} - #{$heightFooter});
  .center-page {
    &.isOpen {
      width: calc(100% - #{$sidebar-width-lg});
    }

    &.noFullScreen {
      padding-right: 75px;
      padding-left: 75px;
      padding-top: 75px;
    }
    &.noFullScreenFrame {
      padding-right: 75px;
      padding-left: 75px;
      padding-top: 0px;
    }
  }

  @media (max-width: $md-device) {
    .center-page {
      margin-left: 32px;
      margin-right: 32px;
      &.isOpen {
        width: calc(100% - #{$sidebar-width-lg});
      }

      &.noFullScreen {
        padding-top: 75px;
        padding-left: 50px;
        padding-right: 50px;
      }
      &.noFullScreenFrame {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 0px;
      }
    }
  }
  //display: flex;
  /*   & .map-container {
    width: 100%;
  } */

  /*   & .sidebar-container {
    position: fixed;
    z-index: 100;
    top: 80px;
    right: 0;
    height: 100%;

    &.open {
      //padding: 15px;
      width: 25%;
    }
  } */
}
