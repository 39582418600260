@import 'variables';
.shared-tabs {
  display: flex;
  flex-wrap: wrap;
  & .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f6f9;
    height: 38px;
    border-radius: 21px;
    min-width: 124px;
    font-family: 'Roboto-medium';
    font-size: 16px;
    margin-right: 12px;
    margin-bottom: 6px;
    cursor: pointer;
    &.active {
      background: #1b3aa6;
      color: #fff;
    }
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
