@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';
@import './../../../components/shared/mediaQuery';

.game-question-container {
  margin-top: 25px;
  padding: 8px;
  font-size: 1.2em;
  padding: 20px;
  flex-direction: column;

  .question-item {
  }

  .question-description-item {
  }

  @media (max-width: $md-device) {
    .question-item {
      font-size: 14px;
    }

    .question-description-item {
      font-size: 14px;
    }
  }
}
