$fontSizeText: 24px !default;
$fontSizeTextMobile: 16px !default;
$fontFamilyTextHeavy: 'Roboto-heavy' !default;
$fontFamilyTextMedium: 'Roboto-medium' !default;
$fontFamilyTextRegular: 'Roboto-regular' !default;
$fontFamilyTextEmphasis: 'Roboto-italic' !default;
$primary: #0393f3 !default;
$error: #a10000 !default;
$colorText: #445167d9 !default;
$letterSpacingText: 0 !default;
$lineHeightText: 19px !default;
