@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';
@import './../../../components/shared/mediaQuery';

.microgame-container {
  //margin-right: 75px;
  //padding-top: 20px;

  .bg-image {
    background-attachment: fixed;
    width: 100%;
    height: calc(100vh - #{$heightHeader} - #{$heightFooter});
    left: 0px;
    background-size: cover;
    position: absolute;
    z-index: 0;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    position: fixed;
    top: $heightHeader;
  }
  .content-list {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    position: relative;
    z-index: 1;
    padding: 25px;
    background-color: $bg-2;
  }
  @media (max-width: $md-device) {
    margin-right: 50px;
  }
}

.row-game-item {
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 6px 12px;
  @include box-shadow-center-hover;
}

.modal-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .summary-block-info {
    background-color: $color-1;
    padding: 0px 6px;
    border-radius: $border-radius;
    margin-right: 0px;
    margin-top: 4px;
    @include box-shadow-center;
  }
}
