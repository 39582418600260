$errorInput: #a10000 !default;

$widthInput: 100% !default;

$heightInput: 32px !default;
$heightMediumInput: 28px !default;
$heightSmallInput: 24px !default;

$fontFamilyInput: 'Roboto-regular' !default;

$fontSizeInput: 14px !default;
$fontSizeMediumInput: 16px !default;
$fontSizeSmallInput: 16px !default;

$lineheightMediumInput: 24px !default;
$lineheightSmallInput: 19px !default;

$borderRadiusInput: 8px !default;
$boxShadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
