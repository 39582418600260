@import '_variables';

.tabs-badge-container {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  & .tabs-badge {
    background-color: $primary;
    opacity: 0.5;
    width: $tabsBadgeWidth;
    height: $tabsBadgeHeight;
    border: $tabsBadgeBoder;
    color: $tabsBadgeColor;
    font-size: $tabsBadgeFontSize;
    font-weight: $tabsBadgeFontWeight;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.selected {
      background-color: $primary;
      opacity: 1;
    }
  }
}
