$bgTitleAccordion: #fff !default;
$colorTitleAccordion: #34a381 !default;
$heightTitleAccordion: 32px !default;
$fontSizeTitleAccordion: 14px !default;
$letterSapcingTitleAccordion: 1px !default;
$borderRadiusTitleAccordion: 8px !default;
$minWidthTitleAccordion: 250px !default;

$fontFamilyIconDownAccordion: 'icon-pack' !default;
$fontFamilyIconDownAccordionBold: 'Roboto-bold' !default;
$fontSizeIconDownAccordion: 23px !default;
$iconDownAccordion: '\e926' !default;
