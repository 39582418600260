@import './../../../../components/shared/variables/';
@import './../../../../components/shared/mixin';
@import './../../../../components/shared/mediaQuery';

.navbar {
  background-color: $bgHeader;
  height: 88px;
  color: #000;
  //border-bottom: $navbar-border-bottom;
  @include box-shadow-bottom;

  .navbar-left {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;

    .icon {
      margin-right: 12px;
      color: $primary-complement;
      cursor: pointer;
    }
  }

  .navbar-center {
    display: flex;
    justify-content: center;
    flex: 1;
  }

  .navbar-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .dropdown {
      .nav-link {
        color: $primary-complement;
      }
    }

    .dropdown-menu {
      left: -50px;
      border: none;
      border-radius: 8px;
      @include box-shadow-center;
    }
  }

  @media (max-width: $md-device) {
    .navbar-center {
      img {
        height: 36px;
      }
    }
  }
}
