@import 'variables';

.shared-textArea {
  width: $widthTextArea;
  height: $heightTextArea;
  border-radius: $borderRadiusTextArea;
  font-size: $fontSizeTextArea;
  font-family: $fontFamilyTextArea;
  border: none;
  &.error {
    border-color: $error;
  }
}
.game-textArea {
  width: $widthTextArea;
  height: $heightTextAreaGame;
  resize: vertical;
  overflow: hidden;
  display: block;
  border-radius: $borderRadiusTextArea;
  font-size: $fontSizeTextArea;
  font-family: $fontFamilyTextArea;
  border: none;
  &.error {
    border-color: $error;
  }
}
