@import 'variables';
.shared-tabs {
  display: flex;
  flex-wrap: wrap;
  &.nav-tabs {
    border: none;
  }
  & .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-tab;
    border: none;
    color: white;
    height: 38px;
    border-radius: $border-radius;
    min-width: 124px;
    font-family: 'Roboto-medium';
    font-size: 16px;
    margin-right: 12px;
    margin-bottom: 6px;
    cursor: pointer;
    box-shadow: $box-shadow;
    &.active {
      background: $primary-complement;
      color: white;
    }
    &:hover {
      box-shadow: $box-shadow-hover;
    }
    &.disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
}
