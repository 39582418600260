@import 'variables';
.shared-card {
  width: $widthCard;
  height: $heightCard;
  border-radius: $borderRadiusCard;
  border: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  .card-header {
    background-color: $headerColor;
    border: none;
    padding-top: 36px;
  }
}
