@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';
@import './../../../components/shared/mediaQuery';
@import './../../../components/shared/table/table/variables';

table.rank {
  background-color: #fdebcc;
  th {
    background-color: #f9b234;
  }
  .current {
    background-color: #f9e334;
    font-weight: 900;
  }
}
