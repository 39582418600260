@import 'variables';
@import './../../../mediaQuery.scss';

.shared-checkbox {
  display: flex;
  align-items: center;

  .check {
    visibility: hidden;
    width: $sizeEmptyCheckbox;
    height: $sizeEmptyCheckbox;

    &:before {
      visibility: visible;
      font-family: $fontFamilyIconCheckbox;
      font-size: $sizeEmptyCheckbox;
      content: $iconEmptyCheckbox;
      position: absolute;
      color: $colorCheckbox;
      top: -5px;
    }

    &:checked {
      &:after {
        visibility: visible;
        font-family: $fontFamilyIconCheckbox;
        content: $iconCheckCheckbox;
        position: absolute;
        font-size: $sizeCheckCheckbox;
        color: $colorCheckbox;
        top: -5px;
        left: 0px;
      }
    }
  }

  @media (max-width: $md-device) {
    .check {
      width: $sizeEmptyCheckbox-sm;
      height: $sizeEmptyCheckbox-sm;

      &:before {
        font-size: $sizeEmptyCheckbox-sm;
      }

      &:checked {
        &:after {
          font-size: $sizeCheckCheckbox-sm;
        }
      }
    }
  }
}
