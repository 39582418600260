$primary: #01a381;
$success: #7ed321;
$error: #a10000;
$warning: #ffcd00;
$secondary: #ffffff;
$logo-primary: #0077b7;
$logo-secondary: #e40422;

$bg-1: #fff;
$bg-2: #f5f6f9;
$bg-3: #fafafa;
$bg-3a: #dfe6eb66;
$bg-4: #c9cbd9;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
);

$body-bg: $bg-2;
$font-family-base: 'Roboto';
$bgGradientPrimary: linear-gradient(212deg, #0091f6 0%, #241d8c 100%);
$bgGradientSecondary: linear-gradient(212deg, #ffffff 0%, #ddedfa 100%);

$borderRadiusButton: 8px;

$font-family-regular: 'Roboto-regular';
$font-family-bold: 'Roboto-regular';
$font-family-medium: 'Roboto-medium';
$font-family-light: 'Roboto-light';

$form-group-margin-bottom: 4px;
$input-placeholder-color: #44516773;

$container-max-widths: (
  sm: 540px,
  md: 960px,
  lg: 1024px,
  xl: 1440px,
);

/* toast */
$toast-border-radius: $borderRadiusButton;
$rct-icon-color: $primary;
$rct-title: 14px;
