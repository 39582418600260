@import './../../../components/shared/variables';
@import './../../../components/shared/mixin';
@import './../../../components/shared/mediaQuery';

.game-play-container {
  width: 400px;
  height: 400px;
  margin: 25px auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: $md-device) {
    width: 100%;
    height: 100%;
    padding: 20px;

    img {
      width: 180px;
      height: 180px;
    }
  }
}
