html {
  height: auto !important;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-auth {
}

.hidden {
  display: none !important;
}
