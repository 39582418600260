@import 'variables';
@import '../../mixin';
@import '../../../shared/mediaQuery';

.shared-baloon {
  position: absolute;
  bottom: 12px;
  left: 30px;
  z-index: 55;
  display: flex;
  align-items: flex-start;
  margin-right: 20px;

  & .img-baloon {
    height: 350px;
  }

  & .closeBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -14px;
    top: -14px;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 50%;
    color: #34a381;
  }

  & .baloon-content {
    @include box-shadow-center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 520px;
    padding: 16px;
    background-color: #fff;
    min-width: 270px;
    border-radius: $border-radius;

    p {
      margin-bottom: 4px;
    }
  }

  @media (max-width: $md-device) {
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
    left: 10px;
    bottom: unset;

    z-index: 1000;

    & .img-baloon {
      height: 150px;
    }

    & .closeBtn {
      right: 0px;
      top: 0px;
    }
  }

  @media (max-width: $sm-device) {
    & .img-baloon {
      display: none;
    }
  }
}
