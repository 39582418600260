@import 'variables';

.badges-info {
  width: $singleBadgeWidth;
  margin: 10px;
}

.badge-last-row {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  height: $badgeInfoRowHeight;
}

.badge-image {
  height: $badgeImgContainerHeight;
  display: flex;
}

.badge-first-row {
  height: $badgeInfoRowHeight;
}
