@import 'variables';

.microbadge-container {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  & > div {
    display: flex;
    flex-direction: row;
  }
}
