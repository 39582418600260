@import './../../../../components/shared/variables';
@import './../../../../components/shared/mixin';
@import './../../../../components/shared/mediaQuery';

@import 'toggleToolbarButton';
@import 'toolbarBtn';
@import 'toolbarTop';
@import 'toolbarRight';
@import 'toolbarLeft';

.sidebar-container {
  position: fixed;
  z-index: 100;
  top: $heightHeader;
  right: 0;
  height: 100%;
  @include box-shadow-left;

  &.open {
    //padding: 15px;
    width: $sidebar-width-lg;
  }

  .container-sidebar {
    // border-left: $sidebar-border-left;
    background: $sidebar-background;
    height: calc(100vh - #{$heightHeader} - #{$heightFooter});
    padding: 0px;
    overflow-y: auto;
    padding-top: 15px;

    &.open {
      padding: 15px;
    }
  }

  @media (max-width: $lg-device) {
    &.open {
      width: $sidebar-width-lg;
    }
  }

  @media (max-width: $md-device) {
    &.open {
      width: $sidebar-width-md;
    }
  }
}
