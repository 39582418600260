@import 'variables';
@import '../../../shared/mediaQuery';

.heading-4 {
  font-family: $fontFamilyHeading4Regular;
  font-size: $fontSizeHeading4;
  color: $colorHeading4;
  letter-spacing: $letterSpacingHeading4;
  line-height: $lineHeightHeading4;
  @media (max-width: $lg-device) {
    font-size: $fontSizeHeading4Mobile;
  }
}

.heavy {
  font-family: $fontFamilyHeading4Heavy;
}
.medium {
  font-family: $fontFamilyHeading4Medium;
}
