@import './../../../components/shared/variables';
@import './../../../components/shared/mediaQuery';

.end-game-container {
  margin-top: 25px;
  font-size: 1.2em;
  padding: 20px;
  justify-content: center;
  flex-direction: column;

  .block-text {
    text-align: center;
    margin: 14px 0px;
  }

  .summary {
    display: flex;
    justify-content: center;

    .summary-item {
      padding: 12px 20px;
      text-align: center;
      margin: 12px;
      flex-direction: column;
      justify-content: center;
    }
  }

  @media (max-width: $md-device) {
    padding: 12px;

    .block-text {
      h3 {
        font-size: 16px;
        font-weight: bold;
      }

      font-size: 14px;
    }

    .summary {
      font-size: 14px;

      .summary-item {
        padding: 4px 4px;
        margin: 8px;
      }
    }
  }
}
