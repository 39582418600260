@import 'variables';
@import '../../../shared/mediaQuery';

.parag {
  font-family: $fontFamilyTextRegular;
  font-size: $fontSizeText;
  color: $colorText;
  letter-spacing: $letterSpacingText;
  line-height: $lineHeightText;
  @media (max-width: $lg-device) {
    font-size: $fontSizeTextMobile;
  }
  .heavy {
    font-family: $fontFamilyTextHeavy;
  }
  .medium {
    font-family: $fontFamilyTextMedium;
  }
  .medium-link {
    font-family: $fontFamilyTextMedium;
    color: $primary;
  }
  .emphasis {
    font-family: $fontFamilyTextEmphasis;
  }
  .caption {
    opacity: 0.65;
  }
  .caption {
    opacity: 0.45;
  }
  .link {
    color: $primary;
  }
  .error {
    color: $error;
  }
  .reverse {
    color: #fff;
  }
}
