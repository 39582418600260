@import 'variables';

.roleManagement-container {
  margin-top: 20px;
  padding-bottom: 40px;

  .nested-accordion {
    margin-bottom: 8px;

    .shared-boxContent {
      margin: 8px 15px;
    }
  }

  .no-nested-accordion {
    margin-bottom: 8px;

    .shared-boxContent {
      margin: 0px;
    }
  }

  .shared-boxContent {
    display: block;
    margin-bottom: 12px;
  }
}
