@import './../../../components/shared/variables';
@import './pinPopup';

.scene-container {
  display: flex;
  justify-content: center;
  & > div {
    position: absolute;
  }
}
.sample-canvas {
  height: calc(100vh - #{$heightHeader} - #{$heightFooter});
  //margin-left: -15px;
  display: flex;
  justify-content: center;
}
